.jumping-dots span {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    animation: jump 1s infinite;
    display: inline-block;
  }
  
  .jumping-dots .dot-1 {
    background-color: #838383;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
    animation-delay: 200ms;
  }
  
  .jumping-dots .dot-2 {
    background-color: #838383;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
    animation-delay: 400ms;
  }
  
  .jumping-dots .dot-3 {
    background-color: #838383;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
    animation-delay: 600ms;
  }
  
  @keyframes jump {
    0% {
      bottom: 0px;
    }
    20% {
      bottom: 5px;
    }
    40% {
      bottom: 0px;
    }
  }